<div class="modal-header">
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	{{ message }}
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-danger" (click)="status=false;bsModalRef.hide()">No</button>
	<button type="button" class="btn btn-primary" (click)="status=true;bsModalRef.hide()">Yes</button>
</div>