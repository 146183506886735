<div class="modal-header">
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="status=null;bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body row" *ngIf="items">
	<div *ngFor="let item of items" [class]="items?.length==1?'col-md-12':'col-md-6'">
		<figure class="cstm_img">
			<img [src]="item?.document?.media_file?image+item?.document?.media_file:'assets/img/doc.jpg'" alt="">
		</figure>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-danger" (click)="status=false;bsModalRef.hide()">Reject</button>
	<button type="button" class="btn btn-primary" (click)="status=true;bsModalRef.hide()">Approve</button>
</div>
