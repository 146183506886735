export const urls = {
    getUsers: 'admin/get-all-users/',
    changePassword : 'admin/change-password/',
    updateProfile:'admin/update-profile/',
    getBankDetails:'admin/get-all-banks-with-pagination/',
    createBank:'admin/create-bank/',
    deleteBank:'admin/delete-bank-by-pk/',
    changeStatus:'admin/accept-reject--user-by-userid/',
    changeFlag:'admin/change-flag-status-by-userid/',
    deleteUser:'admin/delete-user-by-pk/',
    addUser:'admin/add-user/',
    addMedia:'upload/media/',
    getUserById:'admin/get-user-by-pk/',
    getAllCurrency:'admin/get-all-currencies/',
    updateCurrency:'admin/update-currency-rates-by-pk/',
    updateUserById:'admin/update-user-by-id/',
    searchBank:"admin/search-bank/",
    adminbakDetails:"admin/add-update-bank-details/",
    getDashboard:'admin/get-admin-dashboard-counts/',
    getRevenueGraph:'admin/get-total-revenue-graph/',
    getOrderGraph:'admin/get-total-orders-graph/',
    getNewOrderGraph:'admin/get-new-orders-graph/',
    getReferalAmount:'admin/get-referal-amount/',
    updateReferalAmount:'admin/add-update-referal-code-amount/',
    getReferalHistory:'admin/get-referal-amount-history/',
    deleteAmountHistory:'admin/delete-referal-amount-history/',
    getUserReferHistory:'admin/get-user-refer-history/',
    getWalletAddress:'admin/get-wallet-addresses/',
    updateWalletAddress:'admin/update-wallet-address/',
    getNotification:'admin/get-user-first-name/',
    getDocumentByUserId:'admin/get-user-documents-by-userid/',
    getFvByUserId:'admin/get-user-facial-images-by-userid/',
    acceptFv:'admin/accept-facials-by-userid/',
    rejectFvDocByUserId:'admin/reject-user-by-userid/',
    acceptDoc:'admin/accept-documents-by-userid/',
    createNotification:'admin/create-notifications/',
    getAllNotifiation:'admin/get-all-notifications/',
    getAllPayment:'admin/get-transaction-requests/',
    exportAllPayment : 'admin/get-transaction-requests-without-pagination/',
    userExportCsv:'admin/get-all-users-without-pagination/',
    bankExportCsv:'admin/get-all-banks/',
    sendEmail:'admin/send-mail/',
    requestAccept:'payment/change-payment-status/',
    requestReject:'payment/change-payment-status/',
    getTransection:'admin/get-payment-requests-by-userid/',
    getBalance:'admin/get-all-balances-by-userid/',
    getunReadRequest : 'payment/get-dashboard-payment-request-counts/',
    readunReadRequest : 'payment/change-payment-request-to-read/',
    buyOrders : 'admin/get-total-buy-orders-graph/',
    sellOrders : 'admin/get-total-sell-orders-graph/',
    depositOrders : 'admin/get-total-deposit-orders-graph/',
    withdrawOrders : 'admin/get-total-withdraw-orders-graph/',
    getFees : 'admin/get-total-service-fee-orders-graph/',
    referExportCsv:'admin/get-user-refer-history-without-pagination/',
    getMaintenance:'admin/get-website-maintenance-status/',
    postMaintenance:'admin/update-website-maintenance-status/',
    getSubAdminList:'sub-admin/get-all-subadmin-module',
    addSubAdmin  : 'sub-admin/create/',
    getSubAdminById : 'sub-admin/get-details-by-id/',
    deleteSubAdmin:'sub-admin/delete/',
    subAdminList:'sub-admin/get-all-pagination-list/',
    changeSubAdminStatus:'sub-admin/change-status/',
    editSubAdminStatus:'sub-admin/update-details/',
    verifyOtp : 'user/two-factor-authentication/',
    getProfile : 'admin/get-profile/',
    twofactorkey:'admin/change-user-two-factor-authentication-status/',
    exportCsvRequest:'admin/get-transaction-requests-csv/',
    sendPhoneOtp : 'user/send-phone-number-otp/',
    verifyPhoneOtp : 'user/send-phone-number-verify-otp/',
    sendEmailOtp : 'user/resend-mail-otp/',
    getQRCode : 'user/get-user-two-factor-authentication-qr-code/',
    sendOtpVerify : 'user/send-phone-number-otp/',
    twoAuthSend : 'user/change-two-factor-authentication-status/',
    sendEmailOtpVerify : 'admin/send-mail-otp/',
    verifyEmailOtpVerify : 'admin/verify-otp/',
    // verifyOtp : 'user/send-phone-number-verify-otp/'
    changeSuspend:'admin/change-suspended-status-by-userid/',
    changeSMSstatus : 'admin/change-user-sms-authentication-status/',
    exportCSVTransactions : 'admin/get-total-buy-orders-csv/',
    getquery:'cms/get-all-contact-us/',
    activate_user:'admin/activate_user/',
    copied:'payment/change-payment-request-to-copied/'

};