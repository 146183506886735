<div class="modal-header">
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close"  (click)="status=null;bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
    <div class="images justify-content-center">
        <figure class="d-flex justify-content-center">
             <img [src]=" image || 'assets/img/doc.jpg' " style="height: 350px;" alt="">
             </figure>
            </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="status=false;bsModalRef.hide()">Reject</button>
                <button type="button" class="btn btn-primary" (click)="status=true;bsModalRef.hide()">Approve</button>
            </div>
