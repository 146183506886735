<div class="modal-header">
	<h4 class="modal-title pull-left">{{title}}</h4>
	<button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="form-row mt-3">
		<div class="form-group col-md-12">
			<label for="inputPassword4">Reason</label>
			<input type="text" [(ngModel)]="descripition" class="form-control" id="inputPassword4">
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (click)="submit()" >Send</button>
</div>