import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = JSON.parse(localStorage.getItem(environment.storageKey));
        if (currentUser && currentUser.token) {
            let requestTemp = {
                // 'device-type' : 'WEB',
            };
            if(request.url.match(environment.baseUrl)) {
                requestTemp['Authorization'] = `Bearer ${currentUser.token}`;
            }
            if(!request.url.match(environment.bitCoinUrl)) {
                request = request.clone({
                    setHeaders: requestTemp
                });
            }
        }

        return next.handle(request);
    }
}
